<template>
  <b-badge
    :variant="`light-${active.color}`"
  >
    {{ active.key }}
  </b-badge>
</template>

<script>
import statusList from '@/mixins/status.json'
import { BBadge } from 'bootstrap-vue'

export default {
  components: {
    BBadge,
  },
  props: {
    value: {
      type: [Number, String, Boolean],
      default: 0,
    },
  },
  data() {
    return {
      statusList,
      active: {
        id: 1,
        key: 'Waiting for confirmation',
        note: 'Đợi xác nhận',
        color: 'success',
      },
      listStatus: [
        {
          id: 1,
          key: 'Bank',
          note: 'Thanh toán chuyển khoản',
          color: 'danger',
        },
        {
          id: 1,
          key: 'COD',
          note: 'COD',
          color: 'success',
        },
      ],

    }
  },
  watch: {
    value(val) {
      if (val) {
        this.active = this.listStatus.filter(x => x.key === (val))
        // eslint-disable-next-line prefer-destructuring
        this.active = this.active[0]
      }
    },
  },
  mounted() {
    this.active = this.listStatus.filter(x => x.key === (this.value))
    // eslint-disable-next-line prefer-destructuring
    this.active = this.active[0]
  },
}
</script>
