<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <div v-if="model.data && model.data !== null">
    <b-row>
      <b-col md="8">
        <b-card id="status" :title="$t('Status')">
          <b-form @submit.prevent>
            <b-row>
              <b-col md="7">
                <b-form-group :label="$t('Order Situation') +`:`" label-cols-md="12">
                  <div class="d-flex align-items-center h-100">
                    <h3 :class="`text-${model.status.color}`">
                      {{ model.status.name }}
                    </h3>
                  </div>
                </b-form-group>
              </b-col>
              <!-- Submit -->
              <b-col v-if="model.order_status_type" md="5" class="text-right">
                <div
                  v-for="(item, index) in model.next_status"
                  :key="index"
                  :class="{ 'mt-1': index > 0 }"
                >
                  <b-button
                    v-if="model.order_status_type === 4"
                    :variant="item.color"
                    type="submit"
                    class="text-right"
                    @click.prevent="checkRuleShipping(item.id)"
                  >
                    {{ item.name }}
                  </b-button>
                  <b-button
                    v-else
                    :variant="item.color"
                    type="submit"
                    class="text-right"
                    @click.prevent="handlerUpdateStatus(item.id)"
                  >
                    {{ item.name }}
                  </b-button>
                </div>
              </b-col>
              <b-col v-if="model.shipping_and_payment.param_ghn" md="5" class="text-right">
                <div v-if="model.shipping_and_payment.param_ghn" class="mt-1">
                  <b-button
                    type="submit"
                    class="text-right"
                    @click.prevent="handlerPrint()"
                  >
                    In vận đơn
                  </b-button>
                </div>
                <div v-if="model.shipping_and_payment.param_ghn" class="mt-1">
                  <b-button
                    type="submit"
                    class="text-right"
                    @click="getStatusOrderGhn()"
                  >
                    Cập nhật trạng thái đơn hàng
                  </b-button>
                </div>
                <div v-if="model.shipping_and_payment.param_ghn" class="mt-1">
                  <a
                    :href="`https://donhang.ghn.vn/?order_code=${model.shipping_and_payment.param_ghn}`"
                    target="_blank"
                    type="submit"
                    class="text-right"
                  >
                    Xem vận đơn
                  </a>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
        <b-card id="billing" :title="$t('Billing Information')">
          <b-form>
            <b-row>
              <b-col md="6">
                <b-row>
                  <b-col md="12">
                    <b-form-group :label="$t('Order ID')+`:`" label-cols-md="12">
                      <div class="d-flex align-items-center h-100">
                        {{ model.data.order_no }}
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group :label="$t('Order date')+`:`" label-cols-md="12">
                      <div class="d-flex align-items-center h-100">
                        {{ dateFormatUTC(model.data.created_at) }}
                      </div>
                    </b-form-group>
                  </b-col>
                  <!-- <b-col md="12">
                    <b-form-group :label="$t('Phone number')+`:`" label-cols-md="12">
                      <div class="d-flex align-items-center h-100">
                        {{ model.seller.phone }}
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group :label="$t('Recieving address')+`:`" label-cols-md="12">
                      <div class="d-flex align-items-center h-100">
                        {{ model.seller.address }}
                      </div>
                    </b-form-group>
                  </b-col> -->
                </b-row>
              </b-col>
              <b-col md="6">
                <b-row>
                  <b-col md="12">
                    <b-form-group :label="$t('Shipping service')+`:`" label-cols-md="12">
                      <div>
                        <span v-if="model.data.type == 1">Shopee</span>
                        <span v-else-if="model.data.type == 2">Lazada</span>
                        <span v-else-if="model.data.type == 3">Tiktok</span>
                        <span v-else>{{ model.shipping_and_payment.shipping_method }}</span>
                        <div>
                          Mã vận đơn: {{ model.shipping_status === 1 ? model.shipping_and_payment.param_ghn : model.shipping_and_payment.param_ghtk }}
                        </div>
                        <!-- <div v-if="model.shipping_and_payment.param_ghn && model.shipping_status == 1" class="info-ghn">
                          <div>Trạng thái: {{ $store.state.gData.ghnStatus[ghnData.status] || '...' }}</div>
                          <button type="button" @click="getStatusOrderGhn()">
                            Cập nhật trạng thái đơn hàng
                          </button>
                        </div> -->

                        <div v-if="model.shipping_and_payment.param_ghtk && model.shipping_status == 2" class="info-ghn">
                          <div>Trạng thái: {{ ghtkData.status || '...' }}</div>
                          <button type="button" @click="getStatusOrderGhtk()">
                            Cập nhật trạng thái đơn hàng
                          </button>
                        </div>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group :label="$t('Payment method')+`:`" label-cols-md="12">
                      <div v-if="model.data.type == 0" class="d-flex align-items-center h-100">
                        <PaymentMethod1 :value="model.shipping_and_payment.payment_method" />
                      </div>
                      <div v-else class="d-flex align-items-center h-100">
                        {{ model.shipping_and_payment.payment_method_channel }}
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col v-if="model.shipping_and_payment.payment_status" md="12">
                    <b-form-group :label="$t('Payment status')+`:`" label-cols-md="12">
                      <div class="d-flex align-items-center h-100">
                        <PaymentStatus :value="model.shipping_and_payment.payment_status" />
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <vue-good-table
              ref="promotion-products"
              class="custom-good-table custom-good-table-ctrl-border my-2"
              :columns="columns"
              :rows="model.list"
            >
              <template
                slot="table-column"
                slot-scope="props"
              >
                <span>
                  {{ $t(props.column.label) }}
                </span>
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Name -->
                <span
                  v-if="props.column.field === 'avatar_name'"
                  class="d-flex flex-wrap align-items-center"
                >
                  <b-avatar
                    :src="props.row.avatar"
                    size="lg"
                    class="mr-1"
                  />
                  <router-link
                    class="d-block line-clamp"
                    style="max-width: 320px;"
                    :title="props.row.name"
                    :to="`/product/edit/${props.row.id}`"
                  >
                    {{ props.row.name }}
                  </router-link>
                </span>

                <span
                  v-else-if="props.column.field === 'price1'"
                  class="text-nowrap flex-wrap align-items-center"
                >
                  <div v-if="props.row.newprice">
                    <div>{{ unitFormatOriginal(props.row.newprice) }}</div>
                    <div style="text-decoration-line: line-through;">{{ unitFormatOriginal(props.row.price1) }}</div>
                  </div>
                  <div v-else>{{ unitFormatOriginal(props.row.price1) }}</div>
                </span>

                <span
                  v-else-if="props.column.field === 'subTotal'"
                  class="text-nowrap flex-wrap align-items-center"
                >
                  {{ unitFormatOriginal(props.row.subTotal) }}
                </span>

                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
            <div class="d-flex justify-content-between mt-2">
              <div>
                <div v-if="model.bill.shipping_fee" class="mt-2">
                  <div>Phí vận chuyển thực tế</div>
                  <h3 class="text-danger">
                    {{ unitFormatOriginal(model.bill.shipping_fee) }}
                  </h3>
                </div>
              </div>
              <div class="invoice-total-wrapper">
                <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    {{ $t('Subtotal') }}:
                  </p>
                  <p class="invoice-total-amount">
                    {{ unitFormatOriginal(model.bill.sub_total) }}
                  </p>
                </div>
                <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    {{ $t('Discount') }}:
                  </p>
                  <p class="invoice-total-amount">
                    {{ unitFormatOriginal(model.bill.discount_price) }}
                  </p>
                </div>
                <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    {{ $t('Coupon') }}:
                  </p>
                  <p class="invoice-total-amount">
                    {{ model.bill.coupon_code }}
                  </p>
                </div>
                <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    {{ $t('Shipping cost') }}:
                  </p>
                  <p class="invoice-total-amount">
                    {{ unitFormatOriginal(model.bill.shipping_price) }}
                  </p>
                </div>
                <div class="invoice-total-item">
                  <p
                    id="popover-target-phuphi"
                    class="invoice-total-title"
                    :class="model.surcharge && model.surcharge.length > 0 ? 'text-primary cursor-pointer' : ''"
                  >
                    {{ $t('Phụ phí') }}:
                  </p>
                  <p class="invoice-total-amount">
                    {{ unitFormatOriginal(model.surcharge_money) }}
                  </p>
                </div>
                <b-popover
                  v-if="model.surcharge && model.surcharge.length > 0"
                  target="popover-target-phuphi"
                  triggers="hover"
                  no-fade
                >
                  <template #title>
                    Phụ phí
                  </template>
                  <div v-for="item in model.surcharge" :key="item.id" class="d-flex justify-content-between mb-1">
                    <span class="mr-4">{{ item.name }}</span>
                    <span>{{ unitFormatOriginal(item.money) }}</span>
                  </div>
                </b-popover>
                <hr class="my-50">
                <div class="invoice-total-item">
                  <p class="invoice-total-title">
                    {{ $t('KH thanh toán') }}:
                  </p>
                  <strong class="invoice-total-amount">
                    {{ unitFormatOriginal(model.bill.total) }}
                  </strong>
                </div>
              </div>
            </div>
          </b-form>
        </b-card>
      </b-col>
      <b-col md="4">
        <Fieldset v-if="param_ghn === null && param_ghtk === null" id="history" :title="$t('Transporters')">
          <div
            v-for="(item) in feeShip"
            :key="item.id"
            class="d-flex justify-content-between align-items-center p-2 mt-1 css-shipping"
            :class="feeShippingSelected && feeShippingSelected.id === item.id ? 'active' : ''"
            @click="setTransporters(item)"
          >
            <div>
              <img class="mr-2" width="130" :src="item.avatar" alt="GHN">
              <span class="d-block">{{ item.name }}</span>
            </div>
            <div v-if="item.status === 1">
              {{ unitFormatOriginal(item.fee) }}
            </div>
            <div v-else>
              Chưa kết nối
            </div>
          </div>
        </Fieldset>
        <Fieldset id="buyer" :title="$t('Receiver Information')">
          <b-form-group :label="$t('Fullname')+`:`" label-cols-md="12">
            <div class="d-flex align-items-center h-100">
              {{ model.buyer.fullname }}
            </div>
          </b-form-group>
          <b-form-group label="Email:" label-cols-md="12">
            <div class="d-flex align-items-center h-100">
              {{ model.buyer.email }}
            </div>
          </b-form-group>
          <b-form-group :label="$t('Phone number')+`:`" label-cols-md="12">
            <div class="d-flex align-items-center h-100">
              {{ model.buyer.phone }}
            </div>
          </b-form-group>
          <b-form-group :label="$t('Address')+`:`" label-cols-md="12">
            <div class="d-flex align-items-center h-100">
              {{ model.buyer.address }}
            </div>
          </b-form-group>

          <b-form-group v-if="model.buyer.img_bill" :label="$t('Bill order')+`:`" label-cols-md="12">
            <div class="d-flex align-items-center h-100">
              <a :href="model.buyer.img_bill" target="_blank">
                <img :src="model.buyer.img_bill" alt="" height="100">
              </a>
            </div>
          </b-form-group>
        </Fieldset>
        <Fieldset id="history" :title="$t('Order history')">
          <timeline>
            <timeline-item
              v-for="(item, index) in model.history_status"
              :key="index"
              bg-color="#53bfaf"
              :hollow="false"
            >
              <span :class="`text-${item.status.color}`">{{
                item.status.name
              }}</span>
              <br>
              {{ item.created_at }}
            </timeline-item>
          </timeline>
        </Fieldset>
      </b-col>
    </b-row>
    <Overplay v-if="isLoading" />
  </div>
  <!-- <div v-else>
    <no-data />
  </div> -->
</template>

<script>
/* eslint-disable operator-linebreak */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-destructuring */
/* eslint-disable array-callback-return */
/* eslint-disable object-curly-newline */
import { BRow, BCol, BFormGroup, BButton, BForm, BCard, BPopover, BAvatar } from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import statusList from '@/mixins/status.json'
import { Timeline, TimelineItem } from 'vue-cute-timeline'
// import OrderStatus from '@/views/components/order-status/index.vue'
import PaymentStatus from '@/views/components/order-status/payment_status.vue'
import PaymentMethod1 from '@/views/components/order-status/payment_method.vue'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    Timeline,
    TimelineItem,
    BCard,
    // OrderStatus,
    PaymentStatus,
    PaymentMethod1,
    BPopover,
    // TimelineTitle,
    VueGoodTable,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      required,
      isLoading: false,
      model: {
        data: null,
        list: [],
        next_status: [],
        history_status: [],
        buyer: {},
        seller: {},
        shipping_and_payment: {},
        bill: {},
        param_ghn: null,
      },
      param_ghtk: null,
      param_ghn: null,
      columns: [
        { field: 'no', label: 'No.', sortable: false, tdClass: 'text-center' },
        { field: 'avatar_name', label: 'Products', sortable: false },
        { field: 'quantity', label: 'Quantity', sortable: false, tdClass: 'text-center' },
        { field: 'price1', label: 'Price', sortable: false, tdClass: 'text-center' },
        { field: 'subTotal', label: 'Total', sortable: false, tdClass: 'text-center' },
      ],
      key: 'buso_client_page_config',
      ghnData: {
        status: null,
      },
      ghtkData: {
        status: null,
      },
      feeShip: [
        {
          id: 1,
          name: 'Giao hàng nhanh',
          avatar: '/ghn.svg',
          fee: 0,
          status: 1,
        },
        {
          id: 2,
          name: 'Giao hàng tiết kiệm',
          avatar: '/ghtk.png',
          fee: 0,
          status: 1,
        },
      ],
      modelShipping: {
        shipping_status: 0,
        weight: 0,
        height: 0,
        length: 0,
        width: 0,
        wards_id: null,
        district_id: null,
        province_id: null,
      },
      feeShippingSelected: null,
      modelPickPackage: {},
      storage: {},
      statusOptions: [],
    }
  },
  async created() {
    this.loading()
    this.getOrderStatus()
    await this.getStorage()
    await this.loadDetail()
    await this.loadStorageDetail()
    await this.loadProvinceGHN()
    await this.loadDistrictGHN()
    await this.loadWardsGHN()
    await this.getFeeGHN()
    await this.getFeeGHTK()
    await this.loading()
  },
  methods: {
    loading() {
      this.isLoading = !this.isLoading
    },
    generateSlug(name) {
      this.model.slug = this.convertToSlug(name)
    },
    async getStorage() {
      const res = await this.mixGet('/storages', { limit: 1000 })
      if (res.status) {
        if (res.data) {
          this.storage = res.data.items.find(x => x.is_default === 1)
        }
      }
    },
    async getOrderStatus() {
      const res = await this.mixGet('/orders_status', { site_id: JSON.parse(localStorage.getItem('siteID')).id_number })
      if (res.status) {
        if (res.data) {
          this.statusOptions = res.data.items
        }
      }
    },
    async loadStorageDetail() {
      const res = await this.mixGet(`/storage/${this.storage.id_number}`)
      if (res.status) {
        this.modelPickPackage = {
          phone: res.data.phone,
          pick_name: res.data.users_name,
          users_phone: res.data.users_phone,
          users_email: res.data.users_email,
          address: res.data.address,
          is_active: res.data.is_active,
          province_id: res.data.province_id,
          province_name: res.data.province_name,
          district_id: res.data.district_id,
          districts_name: res.data.districts_name,
          ward_id: res.data.ward_id,
          ward_name: res.data.wards_name,
        }
      }
    },
    async loadDetail() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/order/${this.$route.params.id}?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.param_ghn = res.data.data[0].param_ghn || null
            this.param_ghtk = res.data.data[0].param_ghtk || null
            this.model.id_number = res.data.data[0].id_number
            this.model.surcharge = []
            this.model.surcharge_money = res.data.data[0].surcharge
            this.model.next_status = []
            this.model.order_status_type = res.data.data[0].order_status_type
            this.model.list = []
            this.model.history_status = []
            this.model.shipping_status = res.data.data[0].shipping_status
            this.model.seller = await this.loadConfig()
            if (
              JSON.parse(res.data.data.history_status) !== null &&
              JSON.parse(res.data.data.history_status).length > 0
            ) {
              JSON.parse(res.data.data.history_status).map(val => {
                this.model.history_status.push({
                  status: this.statusOptions.filter(
                    x => x.id === Number(val.status),
                  )[0],
                  created_at: val.created_at,
                })
              })
            }
            if (
              (res.data.data[0].id_next_status) !== null
            ) {
              const temp = res.data.data[0].id_next_status.split(',')
              temp.map(val => {
                this.model.next_status.push(
                  this.statusOptions.filter(x => x.id === Number(val))[0],
                )
              })
              if (Number(temp[0]) === 0) this.model.next_status = []
            }
            if (
              (res.data.data[0].surcharge_text) !== null
            ) {
              this.model.surcharge = JSON.parse(res.data.data[0].surcharge_text)
            }
            // this.model.next_status = JSON.parse(res.data.data.next_status)
            this.model.status = this.statusOptions.filter(
              x => x.id === res.data.data[0].status,
            )[0]
            this.model.data = {
              order_no: res.data.data[0].order_no,
              created_at: res.data.data[0].created_at,
              type: res.data.data[0].type,
            }
            this.model.buyer = {
              fullname: res.data.data[0].fullname,
              address: res.data.data[0].address,
              phone: res.data.data[0].phone,
              email: res.data.data[0].email,
              img_bill: res.data.data[0].img_bill,
            }
            const address = JSON.parse(res.data.data[0].address_json)
            if (address) {
              this.model.buyer = {
                ...this.model.buyer,
                delivery_address: res.data.data[0].address,
                delivery_fullname: address.fullname,
                delivery_phone: address.phone,
                delivery_province_name: address.province_name,
                delivery_district_name: address.district_name,
                delivery_ward_name: address.ward_name,
              }
            } else {
              this.model.buyer = {
                ...this.model.buyer,
                delivery_address: res.data.data[0].delivery_address,
                delivery_fullname: res.data.data[0].delivery_fullname,
                delivery_phone: res.data.data[0].delivery_phone,
                delivery_province_name: res.data.data[0].delivery_province_name,
                delivery_district_name: res.data.data[0].delivery_district_name,
                delivery_ward_name: res.data.data[0].delivery_ward_name,
              }
            }
            let shippingMethod = '-- none --'
            if (res.data.data[0].shipping_status === 0) {
              shippingMethod = 'Cửa hàng vận chuyển'
            }
            if (res.data.data[0].shipping_status === 1) {
              shippingMethod = 'Giao hàng nhanh'
            }
            if (res.data.data[0].shipping_status === 2) {
              shippingMethod = 'Giao hàng tiết kiệm'
            }
            this.model.shipping_and_payment = {
              shipping_method: shippingMethod,
              payment_method: res.data.data[0].payment_name_text !== '' && res.data.data[0].payment_name_text !== null && res.data.data[0].payment_name_text !== undefined ? res.data.data[0].payment_name_text : res.data.data[0].payment_type_text,
              payment_status: res.data.data[0].payment_status_text,
              payment_method_channel: res.data.data[0].payment_method,
              payment_type: res.data.data[0].payment_type,
              param_ghn: res.data.data[0].param_ghn,
              param_ghtk: res.data.data[0].param_ghtk,
            }
            this.model.bill = {
              coupon_code: res.data.data[0].coupon_code,
              discount_price: Number(res.data.data[0].coupon_discount),
              shipping_price: Number(res.data.data[0].shipping_price),
              shipping_fee: Number(res.data.data[0].shipping_fee),
              sub_total: Number(res.data.data[0].total),
              total: Number(res.data.data[0].total_full),
            }
            res.data.data[0].list.map((val, index) => {
              this.model.list.push({
                no: index + 1,
                id: val.product_id_code,
                id_number: val.product_id,
                avatar: val.avatar_warehouse || val.avatar_product,
                name: val.product_nm_item,
                name_product_attribute: val.name_product || val.product_nm_item,
                name_attribute: val.name_attribute,
                sku: val.sku_product,
                quantity: val.quantity,
                price1: val.price,
                newprice: val.newprice_item,
                subTotal: val.newprice_item ? Number(val.quantity) * Number(val.newprice_item) : Number(val.quantity) * Number(val.price),
                total: val.total,
                weight: val.weight_product * 1000,
                product_code: val.sku_product,
              })
              this.modelShipping.weight += (val.weight_product) * val.quantity
              if (val.height_product > this.modelShipping.height) this.modelShipping.height = val.height_product
              if (val.length_product > this.modelShipping.length) this.modelShipping.length = val.length_product
              if (val.width_product > this.modelShipping.width) this.modelShipping.width = val.width_product
            })
            if (res.data.data[0].shipping_status === 1) {
              this.ghnCheckOrder()
            }

            if (res.data.data[0].shipping_status === 2) {
              this.ghtkCheckOrder()
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadConfig() {
      let seller = {}
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/setting/${this.key}?site_id=${
            JSON.parse(localStorage.getItem('siteID')).id
          }`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            const data = JSON.parse(res.data.data.value)
            seller = {
              phone: data.filter(x => x.key === 'phone')[0].value,
              address: data.filter(x => x.key === 'address')[0].value,
            }
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return seller
    },
    setTransporters(data) {
      this.feeShippingSelected = data
    },
    // eslint-disable-next-line consistent-return
    checkRuleShipping(data) {
      if (!this.feeShippingSelected) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Thông báo !',
            icon: 'CheckCircleIcon',
            variant: 'danger',
            text: 'Vui lòng chọn nhà vận chuyển cho đơn hàng.',
          },
        })
        return false
      }
      if (this.feeShippingSelected.id === 1) this.ghnCreateOrder(data)
      else this.ghtkCreateOrder(data)
    },
    async handlerUpdateStatus(status) {
      try {
        const params = {
          site_id: JSON.parse(localStorage.getItem('siteID')).id_number,
          status,
        }
        const res = await Request.put(
          this.$http,
          `${process.env.VUE_APP_API_URL}/v2/order/update_status/${this.model.id_number}`,
          params,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Congratulation !',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'Update Trademark success',
              },
            })
            this.loadDetail()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async getFeeGHN() {
      const params = {
        to_ward_code: this.modelShipping.wards_id,
        to_district_id: this.modelShipping.district_id,
        weight: Math.round(this.modelShipping.weight * 1000),
        width: this.modelShipping.width,
        length: this.modelShipping.length,
        height: this.modelShipping.height,
        insurance_value: this.model.bill.sub_total,
        service_type_id: 2,
        from_district_id: 1443,
      }
      const res = await this.mixGet('/ghn/shipping_fee', params)
      if (res.status) {
        this.feeShip[0].fee = res.data.total
      }
    },
    async getFeeGHTK() {
      const params = {
        pick_province: this.modelPickPackage.province_name,
        pick_district: this.modelPickPackage.districts_name,
        province: this.model.buyer.delivery_province_name,
        district: this.model.buyer.delivery_district_name,
        address: this.model.buyer.delivery_address,
        weight: Math.round(this.modelShipping.weight * 1000),
        deliver_option: 'none',
        value: this.model.bill.sub_total,
      }
      const res = await this.mixGet('/ghtk/get_shipment_fee', params)
      if (res.status) {
        this.feeShip[1].fee = res.data.fee
      }
    },

    async ghnCreateOrder(nextStatus) {
      try {
        this.loading()
        const params = {
          site_id: JSON.parse(localStorage.getItem('siteID')).id,
          to_name: this.model.buyer.fullname,
          to_phone: this.model.buyer.phone,
          to_address: this.model.buyer.address,
          // get ward_id, district_id from api ghn
          to_ward_code: this.modelShipping.wards_id,
          to_district_id: this.modelShipping.district_id,
          return_phone: '0987654329',
          return_address: '51 Yen The, Phuong 2, Tan Binh, HCM',
          cod_amount: 0,
          // content: 'ko co',
          weight: Math.round(this.modelShipping.weight * 1000),
          length: this.modelShipping.length,
          width: this.modelShipping.width,
          height: this.modelShipping.height,
          insurance_value: this.model.bill.sub_total,
          service_type_id: 2,
          payment_type_id: 1,
          // note: 'ko có',
          required_note: 'KHONGCHOXEMHANG',
          items: JSON.stringify(this.model.list),
          order_id: this.model.id_number,
        }
        if (this.model.shipping_and_payment.payment_type === 1) {
          params.cod_amount = Number(this.model.bill.total)
        }
        if (this.model.shipping_and_payment.payment_type === 2) {
          params.cod_amount = 0
        }
        const res = await Request.post(
          this.$http,
          `${process.env.VUE_APP_API_URL}/ghn/create_order`,
          params,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.handlerUpdateStatus(nextStatus)
            this.loading()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Congratulation !',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'Chuyển đơn hàng qua Giao hàng nhanh thành công!',
              },
            })
          } else {
            this.loading()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async ghnCheckOrder() {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/ghn/get_shipping_order_detail?site_id=${JSON.parse(localStorage.getItem('siteID')).id}&order_code=${this.param_ghn}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.ghnData.status = res.data.data.status
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async ghtkCheckOrder() {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/ghtk/get_shipping_order_detail?site_id=${JSON.parse(localStorage.getItem('siteID')).id}&order_code=${this.param_ghtk}`,
        )

        if (res.status === 200) {
          if (res.data.status) {
            this.ghtkData.status = res.data.data.status_text
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async ghtkCreateOrder(nextStatus) {
      try {
        this.loading()
        const formData = new FormData()
        formData.append('site_id', JSON.parse(localStorage.getItem('siteID')).id)
        formData.append('order_id', this.model.id_number)
        formData.append('name', this.model.buyer.delivery_fullname)
        formData.append('address', this.model.buyer.delivery_address)
        formData.append('province', this.model.buyer.delivery_province_name)
        formData.append('district', this.model.buyer.delivery_district_name)
        formData.append('ward', this.model.buyer.delivery_ward_name)
        formData.append('street', this.model.buyer.delivery_address)
        formData.append('hamlet', 'Khác')
        formData.append('tel', this.model.buyer.delivery_phone)
        formData.append('email', this.model.buyer.email || this.modelPickPackage.users_email)
        formData.append('pick_name', this.modelPickPackage.pick_name)
        formData.append('pick_address', this.modelPickPackage.address)
        formData.append('pick_province', this.modelPickPackage.province_name)
        formData.append('pick_district', this.modelPickPackage.districts_name)
        formData.append('pick_tel', this.modelPickPackage.phone)
        formData.append('pick_money', 0)
        formData.append('return_name', this.modelPickPackage.pick_name)
        formData.append('return_address', this.modelPickPackage.address)
        formData.append('return_province', this.modelPickPackage.province_name)
        formData.append('return_district', this.modelPickPackage.districts_name)
        formData.append('return_ward', this.modelPickPackage.ward_name)
        formData.append('return_tel', this.modelPickPackage.users_phone || this.modelPickPackage.phone)
        formData.append('return_email', this.modelPickPackage.users_email)
        formData.append('value', this.model.bill.sub_total)
        formData.append('products', JSON.stringify(this.model.list))
        formData.append('total_weight', Math.round(this.modelShipping.weight))
        // const params = {
        //   site_id: JSON.parse(localStorage.getItem('siteID')).id,
        //   order_id: this.model.id_number,
        //   name: this.model.buyer.delivery_fullname,
        //   address: this.model.buyer.delivery_address,
        //   province: this.model.buyer.delivery_province_name,
        //   district: this.model.buyer.delivery_district_name,
        //   ward: this.model.buyer.delivery_ward_name,
        //   street: this.model.buyer.delivery_address,
        //   hamlet: 'Khác',
        //   tel: this.model.buyer.delivery_phone,
        //   email: this.model.buyer.email || this.modelPickPackage.users_email,
        //   pick_name: this.modelPickPackage.pick_name,
        //   pick_address: this.modelPickPackage.address,
        //   pick_province: this.modelPickPackage.province_name,
        //   pick_district: this.modelPickPackage.districts_name,
        //   pick_tel: this.modelPickPackage.phone,
        //   pick_money: 0,
        //   return_name: this.modelPickPackage.pick_name,
        //   return_address: this.modelPickPackage.address,
        //   return_province: this.modelPickPackage.province_name,
        //   return_district: this.modelPickPackage.districts_name,
        //   return_ward: this.modelPickPackage.ward_name,
        //   return_tel: this.modelPickPackage.users_phone || this.modelPickPackage.phone,
        //   return_email: this.modelPickPackage.users_email,
        //   value: this.model.bill.sub_total,
        //   products: JSON.stringify(this.model.list),
        //   // pick_address_id: this.param_ghtk.pick_address_id,
        //   total_weight: this.modelShipping.weight,
        // }
        if (this.model.shipping_and_payment.payment_type === 1) {
          formData.append('pick_money', Number(this.model.bill.total))
        }
        if (this.model.shipping_and_payment.payment_type === 2) {
          formData.append('pick_money', 0)
        }
        const res = await Request.post(
          this.$http,
          `${process.env.VUE_APP_API_URL}/ghtk/create_order`,
          formData,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.handlerUpdateStatus(nextStatus)
            this.loading()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Congratulation !',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'Chuyển đơn hàng sang nhà vận chuyển GHTK thành công.',
              },
            })
          } else {
            this.loading()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    // load province, district, ward GHN
    async loadProvinceGHN() {
      const res = await this.mixGet('/ghn/get_province', { limit: 64 })
      if (res.status) {
        const temp = []
        // eslint-disable-next-line array-callback-return
        res.data.map((item, index) => {
          temp.push({
            id: item.ProvinceID,
            name: item.ProvinceName,
            slug: this.convertToSlug(item.ProvinceName),
            NameExtension: item.NameExtension,
          })
          const find = temp[index].NameExtension.includes(this.model.buyer.delivery_province_name)
          if (find) {
            this.modelShipping.province_id = temp[index].id
          }
        })
      }
    },
    async loadDistrictGHN() {
      const res = await this.mixGet('/ghn/get_district', { limit: 200, province_id: this.modelShipping.province_id })
      if (res.status) {
        const temp = []
        // eslint-disable-next-line array-callback-return
        res.data.map((item, index) => {
          temp.push({
            id: item.DistrictID,
            name: item.DistrictName,
            slug: this.convertToSlug(item.DistrictName),
            NameExtension: item.NameExtension ? item.NameExtension : [],
          })
          const find = temp[index].NameExtension.includes(this.model.buyer.delivery_district_name)
          if (find) {
            this.modelShipping.district_id = temp[index].id
          }
        })
        // const slugDistrict = this.convertToSlug(this.model.customer.district_name)
        // const find = temp.includes(x => x.slug === slugDistrict)
        // this.modelShipping.district_id = find.id
      }
    },
    async loadWardsGHN() {
      const res = await this.mixGet('/ghn/get_ward', { limit: 200, district_id: this.modelShipping.district_id })
      if (res.status) {
        const temp = []
        // eslint-disable-next-line array-callback-return
        res.data.map((item, index) => {
          temp.push({
            id: item.WardCode,
            name: item.WardName,
            slug: this.convertToSlug(item.WardName),
            NameExtension: item.NameExtension,
          })
          const find = temp[index].NameExtension.includes(this.model.buyer.delivery_ward_name.trim())
          if (find) {
            this.modelShipping.wards_id = temp[index].id
          }
        })
        // const slugWards = this.convertToSlug(this.model.customer.wards_name)
        // const find = temp.find(x => x.slug === slugWards)
        // this.modelShipping.wards_id = find.id
      }
    },
    async handlerPrint() {
      if (this.model.shipping_and_payment.param_ghn) {
        const res = await this.mixGet('/ghn/gen_token_order', { site_id: localStorage.getItem('siteID').id_number, order_id: this.model.shipping_and_payment.param_ghn })
        if (res.status) {
          if (res.data.mode === 'sandbox') {
            const url = `https://dev-online-gateway.ghn.vn/a5/public-api/printA5?token=${res.data.token}`
            window.open(
              url,
              'popup',
              'width=1200,height=800',
            )
          } else {
            const url = `https://online-gateway.ghn.vn/a5/public-api/printA5?token=${res.data.token}`
            window.open(
              url,
              'popup',
              'width=1200,height=1400',
            )
          }
        }
      } else {
        const res = await this.mixGet('/ghtk/gen_token_order', { site_id: localStorage.getItem('siteID').id_number, order_id: this.model.shipping_and_payment.param_ghn })
        if (res.status) {
          if (res.data.mode === 'sandbox') {
            const url = `https://dev-online-gateway.ghn.vn/a5/public-api/printA5?token=${res.data.token}`
            window.open(
              url,
              'popup',
              'width=1200,height=800',
            )
          } else {
            const url = `https://online-gateway.ghn.vn/a5/public-api/printA5?token=${res.data.token}`
            window.open(
              url,
              'popup',
              'width=1200,height=800',
            )
          }
        }
      }
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
    async getStatusOrderGhn() {
      const res = await this.mixPut(`/ghn/update_status/${this.model.id_number}`, { site_id: JSON.parse(localStorage.getItem('siteID')).id_number })
      if (res.status) {
        this.loadDetail()
      }
    },
    async getStatusOrderGhtk() {
      const res = await this.mixPut(`/ghtk/update_status/${this.model.id_number}`, { site_id: JSON.parse(localStorage.getItem('siteID')).id_number })
      if (res.status) {
        this.loadDetail()
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
.invoice-total-wrapper {
  width: 250px;

  .invoice-total-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
.css-shipping {
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;

  &:hover,
  &.active{
    border-color: var(--success);
  }

  img {
    width: 130px;
  }
}
</style>
